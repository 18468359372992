import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import JadeImage from "../images/jade-image.svg"

import Amazon from "../images/icons/icon-amazon.svg"
import Target from "../images/icons/icon-target.svg"
import Diaper from "../images/icons/icon-diaper.svg"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image/>
    </div>*/}
    <div className="wrapper">
      <img 
        data-sal="slide-up"
        data-sal-duration="700"
        data-sal-easing="ease" 
        className="hero-image" src={JadeImage} alt="illustration of baby Jade"/>
      <div 
      data-sal="slide-up"
      data-sal-duration="700"
      data-sal-easing="ease"
      className="col">
        <p className="card">
          <h3>You're Invited!</h3>
          Since we cannot gather in anyway, <br/>
          Let’s shower the parents from far away. <br/>
          Gifts and cards will show them we care, <br/>
          Even though we wish we could all be there.<br/>
        </p>
        <h3>Baby Shower Details</h3>
        <p>
        Thank you so much for taking the time to visit our baby registry! As much as we wish we can all get together and celebrate in person, we appreciate everyone showing love to our little one from a distance. We can’t wait to introduce you to baby Jade Isabella Dos Santos! 
        </p>
        <p>
        We are registered online with both Target and Amazon; please be sure to leave your name and a message at the end of your purchase so we know who to thank ;-)
        </p>
      </div>
      <div 
        data-sal="slide-up"
        data-sal-duration="700"
        data-sal-easing="ease"
        className="col">
        <h3>Diaper Raffle Details</h3>
        <p>Help contribute to all of our future diaper changes by either giving to the “Group Gift Diaper Contribution” on Amazon or give in the form of a Target gift card and label it “For diapers”. When everyone has entered, we will be raffling off three prizes to those who have contributed. The raffle will take place on November 30th :)</p>
        <p>Again, thank you so much to everyone!</p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
